<template>
  <v-container
    fluid
    class="engineer-detail detail-page white-background main-detail-page"
    v-if="getPermission('engineer:view')"
  >
    <div class="p-5 pb-0">
      <v-row>
        <v-col cols="6" class="pt-0">
          <h1 class="form-title margin-auto">
            {{ engineerArr.full_name }}
            <v-chip
              label
              color="chip-custom-blue"
              outlined
              class="text-white p-3 mr-2"
              style="font-size: 21px !important; font-weight: 700"
              >{{ engineerArr.barcode }}</v-chip
            >
            <Status
              style="margin: 0 !important"
              :status="
                lodash.isEmpty(engineerArr) === false
                  ? engineerArr.activated
                  : false
              "
            ></Status>
            <template
              v-if="engineerArr && engineerArr.role && engineerArr.role.id == 2"
            >
              <v-chip
                label
                outlined
                text-color=""
                dense
                class="ml-2"
                color="blue"
                >Technician</v-chip
              >
            </template>
            <template v-if="engineerArr && engineerArr.is_driver">
              <v-chip
                label
                outlined
                text-color=""
                dense
                class="ml-2"
                color="red"
              >
                Driver</v-chip
              >
            </template>
          </h1>
        </v-col>

        <v-col cols="6" class="pt-0 text-right">
          <template v-if="getPermission('engineer:update')">
            <v-btn
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              :to="
                getDefaultRoute('engineer.update', {
                  param: { id: engineerArr ? engineerArr.id : 0 },
                })
              "
            >
              <v-icon left>mdi-pencil</v-icon>
              Edit
            </v-btn>
          </template>
          <v-btn
            v-on:click="goBack"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            <v-icon left>mdi-keyboard-backspace</v-icon>
            Back
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-tabs
      v-model="engineerTab"
      background-color="transparent"
      centered
      color="cyan"
      fixed-tabs
      class="custom-tab-transparent"
    >
      <v-tab class="font-size-16 font-weight-600 px-2" :href="'#personal-info'">
        <v-icon color="grey darken-4" left>mdi-account-circle</v-icon>
        Basic Info
      </v-tab>
      <!-- <v-tab class="font-size-16 font-weight-600 px-2" href="#delivery">
        <v-icon color="grey darken-4" left>mdi-truck</v-icon>
        Delivery Summary
      </v-tab> -->
      <v-tab class="font-size-16 font-weight-600 px-2" href="#visits">
        <v-icon color="grey darken-4" left>mdi-notebook-check</v-icon>
        Visits Summary
      </v-tab>
      <v-tab
        class="font-size-16 font-weight-600 px-2"
        :href="'#reset-password'"
      >
        <v-icon color="grey darken-4" left>mdi-key</v-icon>
        Reset Password
      </v-tab>
      <v-tab
        class="font-size-16 font-weight-600 px-2"
        :href="'#leave-update'"
        v-if="false"
      >
        <v-icon color="grey darken-4" left>mdi-key</v-icon>
        Leave Update
      </v-tab>
      <v-tab class="font-size-16 font-weight-600 px-2" :href="'#attachments'">
        <v-icon color="grey darken-4" left>mdi-file-multiple</v-icon>
        Additional Document
      </v-tab>
      <v-tab class="font-size-16 font-weight-600 px-2" :href="'#history'">
        <v-icon color="grey darken-4" left>history</v-icon>
        History
      </v-tab>
    </v-tabs>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 90vh; position: relative"
    >
      <v-tabs-items v-model="engineerTab" class="custom-min-height-60vh">
        <v-tab-item :value="'personal-info'">
          <v-row class="justify-center">
            <v-expansion-panels v-model="panel" inset>
              <v-expansion-panel>
                <v-expansion-panel-header class=""
                  ><h2 class="margin-auto color-custom-blue font-weight-600">
                    Personal Info
                  </h2></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <PersonalInfo :engineer="engineerArr"></PersonalInfo>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="
                  engineerArr.allow_leave &&
                  engineerArr.leaves &&
                  engineerArr.leaves.length > 0
                "
              >
                <v-expansion-panel-header class=""
                  ><h2 class="margin-auto color-custom-blue font-weight-600">
                    Leaves
                  </h2></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <table width="100%">
                    <tr>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        Leave
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        Used
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-600 text-capitalize"
                      >
                        Allowed
                      </td>
                    </tr>
                    <tr
                      v-for="(leave, index) in engineerArr.leaves"
                      :key="index"
                    >
                      <td
                        class="pb-2 font-size-18 font-weight-500 text-capitalize"
                      >
                        {{ leave.leave_title }}
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-500 text-capitalize"
                      >
                        {{ leave.leave_used }}
                      </td>
                      <td
                        class="pb-2 font-size-18 font-weight-500 text-capitalize"
                      >
                        {{ leave.leave_allowed }}
                      </td>
                    </tr>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><h2 class="margin-auto color-custom-blue font-weight-600">
                    Emergency Contact
                  </h2></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <EmergencyContact :engineer="engineerArr"></EmergencyContact>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header
                  ><h2 class="margin-auto color-custom-blue font-weight-600">
                    Address
                  </h2></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <EngineerAddress :engineer="engineerArr"></EngineerAddress>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <!-- <v-expansion-panel>
                <v-expansion-panel-header
                  ><h2 class="margin-auto color-custom-blue font-weight-600">
                    Color
                  </h2></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <Color :engineer="engineerArr"></Color>
                </v-expansion-panel-content>
              </v-expansion-panel> -->
            </v-expansion-panels>
          </v-row>
        </v-tab-item>
        <v-tab-item :value="'emergency-contact'">
          <EmergencyContact :engineer="engineerArr"></EmergencyContact>
        </v-tab-item>
        <v-tab-item :value="'engineer-address'">
          <EngineerAddress :engineer="engineerArr"></EngineerAddress>
        </v-tab-item>
        <v-tab-item :value="'color'">
          <Color :engineer="engineerArr"></Color>
        </v-tab-item>

        <v-tab-item v-if="getPermission('equipment:view')" :value="'history'">
          <InternalHistoryDetail
            type="engineer"
            :type_id="engineerArr.id"
          ></InternalHistoryDetail>
        </v-tab-item>

        <!-- <v-tab-item value="delivery">
          <DeliveryListingTemplate
            is-admin
            visit-type="all"
            internal
            :visit-status="0"
            detailRoute="delivery.detail"
            :engineer-id="engineerArr.id"
          ></DeliveryListingTemplate>
        </v-tab-item> -->

        <v-tab-item value="visits">
          <VisitListingTemplate
            is-admin
            visit-type="all"
            internal
            :visit-status="0"
            detailRoute="visit.detail"
            :engineer-id="engineerArr.id"
          ></VisitListingTemplate>
        </v-tab-item>

        <v-tab-item v-if="getPermission('job:view')" :value="'job-summary'">
          <Tickets
            :detail="engineerArr"
            :type_id="engineerArr.id"
            type="engineer"
          ></Tickets>
        </v-tab-item>
        <v-tab-item :value="'reset-password'">
          <PasswordUpdate
            v-on:password:updated="userTab = 'tab-history'"
          ></PasswordUpdate>
        </v-tab-item>

        <v-tab-item :value="'leave-update'">
          <LeaveUpdateEngineer
            :detail="engineerArr"
            :engineer-id="engineerArr.id"
          ></LeaveUpdateEngineer>
        </v-tab-item>

        <v-tab-item :value="'attachments'">
          <div class="service-detail-height">
            <AdditionalDocuments
              :type-id="engineerArr.id"
              class="mx-4"
              type-text="Technician Attachments"
              :type="29"
              :extensive="false"
            ></AdditionalDocuments>
          </div>
        </v-tab-item>

        <v-tab-item :value="'file'" v-if="false">
          <!-- <AdditionalDocuments
            :type-id="engineerArr.id"
            class="mx-4"
            type-text="Technicians"
            :type="29"
            :extensive="true"
          ></AdditionalDocuments> -->
          <div class="service-detail-height">
            <!-- <FileTemplate
              :attachments="engineerArr.attachments"
            ></FileTemplate> -->
          </div>
        </v-tab-item>
      </v-tabs-items>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import Delete from "@/view/pages/partials/Delete.vue";
import PersonalInfo from "@/view/pages/engineer/detail/Personal-Info";
import EmergencyContact from "@/view/pages/engineer/detail/Emergency-Contact";
import EngineerAddress from "@/view/pages/engineer/detail/Engineer-Address";
import Color from "@/view/pages/engineer/detail/Color";
import EngineerMixin from "@/core/lib/engineer/engineer.mixin";
import Status from "@/view/pages/partials/Status.vue";
//import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import PasswordUpdate from "@/view/pages/engineer/PasswordUpdate";
import LeaveUpdateEngineer from "@/view/pages/engineer/Leave-Update-Engineer";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
// import Tickets from "@/view/pages/engineer/Tickets";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";
//import DeliveryListingTemplate from "@/view/pages/delivery/Delivery-Listing-Template";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";

export default {
  mixins: [CommonMixin, ValidationMixin, EngineerMixin],
  name: "engineer-detail",
  title: "Engineer",
  data() {
    return {
      detail: new Object(),
      engineer: 0,
      panel: 0,
      engineerTab: null,
      pageLoading: true,
      deleteDialog: false,
      engineerArr: {},
    };
  },
  components: {
    Tickets,
    Status,
    VisitListingTemplate,
    // DeliveryListingTemplate,
    PersonalInfo,
    EmergencyContact,
    EngineerAddress,
    Color,
    PasswordUpdate,
    LeaveUpdateEngineer,
    AdditionalDocuments,
    InternalHistoryDetail,
    //FileTemplate
  },
  methods: {
    deleteEngineer() {
      const _this = this;
      if (!_this.$refs.engineerDeleteForm.validate()) {
        return false;
      }
    },
  },
  mounted() {
    const _this = this;
    _this
      .getEngineer()
      .then((response) => {
        _this.engineerArr = response;
        _this.$store.dispatch(SET_BREADCRUMB, [
          { title: "Technician", route: "engineer" },
          { title: "Detail" },
          { title: response.barcode },
        ]);
      })
      .catch((error) => {
        _this.logError(error);
        _this.$router.go(-1);
      })
      .finally(() => {
        _this.pageLoading = false;
      });
  },
  created() {
    const _this = this;
    _this.engineer = _this.$route.params.id;
    if (!_this.engineer || _this.engineer <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    getTitle() {
      let result = [];
      if (this.engineerArr && this.engineerArr.customer) {
        result.push(this.engineerArr.customer.display_name);
      }
      if (
        this.engineerArr &&
        this.engineerArr.customer &&
        this.engineerArr.customer.default_person
      ) {
        result.push(this.engineerArr.customer.default_person.display_name);
      }
      if (this.engineerArr && this.engineerArr.barcode) {
        result.push(this.engineerArr.barcode);
      }
      return result.join(" • ");
    },
    countryName() {
      let result = "";
      if (this.lodash.isEmpty(this.engineerArr.country) === false) {
        result = this.engineerArr.country.name;
      }
      return result;
    },
    getStatus() {
      return this.lodash.isEmpty(this.engineerArr) === false
        ? this.engineerArr.activated
        : false;
    },
    getBarcode() {
      return this.lodash.isEmpty(this.engineerArr) === false
        ? this.engineerArr.barcode
        : false;
    },
    getRouteParam() {
      let result = { customer: 0, id: 0 };
      if (this.lodash.isEmpty(this.engineerArr) === false) {
        if (this.engineerArr.id) {
          result.id = this.engineerArr.id;
        }
        if (this.engineerArr.customer) {
          result.customer = this.engineerArr.customer.id;
        }
      }
      return result;
    },
  },
};
</script>
